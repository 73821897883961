import React from "react";
import styled from "styled-components";
import { routes } from "../../lib/routes";
import { TextSection } from "../Shared/Layout/components/TextSection/TextSection";

export interface BottomTextSectionProps {
  className?: string;
}

const BottomTextSectionInner = (props: BottomTextSectionProps) => {
  return (
    <TextSection
      className={props.className}
      title="100% oriented on solutions and praxis"
      description="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Necessitatibus sunt quam voluptatum minima quos reprehenderit autem perferendis beatae sequi vel voluptate omnis est molestias ducimus, pariatur eius voluptatem laborum iure?"
      button="Continue to service"
      buttonUrl={routes.services.to}
    />
  );
};

export const BottomTextSection = styled(BottomTextSectionInner)``;
