import React from "react";
import { BottomTextSection } from "../components/404/BottomTextSection";
import { NotFoundMainSection } from "../components/404/NotFoundMainSection";
import { HeroSection } from "../components/Home/HeroSection/HeroSection";
import { TopHeader } from "../components/Shared/Layout/components/TopHeader/TopHeader";

import Layout from "../components/Shared/Layout/Layout";

const NotFoundPage = () => (
  <Layout>
    <TopHeader title="Ooops :(" />
    <NotFoundMainSection />
    <HeroSection />
    <BottomTextSection />
  </Layout>
);

export default NotFoundPage;
